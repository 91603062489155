import { MlsFeed } from "../models/mls-feed"
import Crud from "./crud"
import { Client } from "./client"

const client = new Client()

export class MlsFeedService extends Crud<MlsFeed> {
    constructor() {
        super("/crm/mls")
    }

    getMlsFeeds() {
        return client.get(this.endpoint)
    }
}
