<template>
    <div class="pb-5 text-lg leading-7 font-medium text-black">Select a branching type:</div>
    <div class="flex flex-col gap-y-3">
        <BranchTypeCard
            v-for="branchType in branchTypes"
            :key="branchType.title"
            :title="branchType.title"
            :condition="branchType.condition"
            :example="branchType.example"
            @click="selectBranchType(branchType.component)"
        ></BranchTypeCard>
    </div>
</template>
<script lang="ts" setup>
import { Component } from "vue"
import BranchTypeCard from "@/components/workflows/sidebar/action/branch/BranchTypeCard.vue"
import SinglePropertyComponent from "@/components/workflows/sidebar/action/branch/SinglePropertyComponent.vue"
import MergeIcon from "@/components/icons/MergeIcon.vue"
import MultiplePropertiesComponent from "@/components/workflows/sidebar/action/branch/MultiplePropertiesComponent.vue"
import MultipleBranchesComponent from "@/components/workflows/sidebar/action/branch/MultipleBranchesComponent.vue"
import PercentageSplitComponent from "@/components/workflows/sidebar/action/branch/PercentageSplitComponent.vue"

const emit = defineEmits(["changeAction"])

const branchTypes = [
    {
        title: "Based on a single property",
        condition: "(if 'property' is)",
        example: "Send contacts to the branch named John if their first name is John.",
        component: SinglePropertyComponent,
    },
    {
        title: "Based on multiple object fields",
        condition: "(if/then)",
        example: "Send contacts to the branch named West coast collection if their location equals Oregon.",
        component: MultiplePropertiesComponent,
    },
    {
        title: "Based on multiple branches filtering",
        condition: "(if/then)",
        example:
            "Send contacts to branch 1 that has a set of filters, if those are true perform action then move on to branch 2 and so on.",
        component: MultipleBranchesComponent,
    },
    {
        title: "Based on percentage split",
        condition: "(if/then)",
        example: "Sends contacts to randomly to percentage split branches.",
        component: PercentageSplitComponent,
    },
]

function selectBranchType(sidebarComponent: Component) {
    emit("changeAction", {
        icon: MergeIcon,
        title: "Configure branch...",
        sidebarComponent,
    })
}
</script>
