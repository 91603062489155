import { defineStore } from "pinia"
import { useGridCollectionStore } from "@/stores/grid-collection"
import { TableState } from "@/shared/models/table"
import { ToastService } from "@/shared/services/toast"
import { TrackEvent } from "@/shared/models/track-event"
import { TrackEventService } from "@/shared/services/track-event"

export const useTrackEventStore = defineStore("track-event", {
    state: () => ({
        tableState: {
            data: [],
            selectedData: [],
            currentPage: 0,
            pageLength: 50,
            numberOfPages: 0,
            total: 0,
            isAllSelected: false,
            isLoading: false,
        } as TableState<TrackEvent>,
        trackEventService: new TrackEventService(),
        toastService: new ToastService(),
        gridCollectionStore: useGridCollectionStore(),
    }),
    actions: {
        async getTrackEvents(agencyId: string) {
            try {
                this.tableState.isLoading = true

                let lastId = null
                let offset = null

                // If we're going to the next page, use keyset pagination.
                if (this.tableState.currentPage === (this.tableState?.lastPage ?? 0) + 1) {
                    lastId =
                        this.tableState.data.length > 0
                            ? this.tableState.data[this.tableState.data?.length - 1]._id
                            : null
                }
                // If we're jumping to a specific page, use offset-based pagination.
                else if (
                    this.tableState.currentPage !== (this.tableState?.lastPage ?? 0) + 1 &&
                    this.tableState.currentPage > 1
                ) {
                    offset = (this.tableState.currentPage - 1) * this.tableState.pageLength
                }

                const filtersGroups = this.gridCollectionStore.grid.filtersGroups.length
                    ? encodeURIComponent(JSON.stringify(this.gridCollectionStore.grid.filtersGroups))
                    : undefined

                const response = await this.trackEventService.getAll(
                    {
                        length: this.tableState.pageLength,
                        column: this.tableState.column,
                        order: this.tableState.order,
                        search: this.tableState.search,
                        filtersGroups,
                        lastId,
                        offset,
                    },
                    agencyId
                )

                if (!response?.data?.length) {
                    this.resetTableState()
                    return
                }

                // Store the first ID of the new page.
                if (this.tableState.firstIds && this.tableState.currentPage) {
                    this.tableState.firstIds[this.tableState.currentPage - 1] = response.data[0]._id as string
                }

                this.tableState.selectedData = []
                this.tableState.data = response.data
                this.tableState.total = response.total
                this.tableState.numberOfPages = Math.ceil(this.tableState.total / this.tableState.pageLength)
                this.tableState.lastPage = this.tableState.currentPage
            } finally {
                this.tableState.isLoading = false
            }
        },

        resetTableState() {
            this.tableState.data = []
            this.tableState.total = 0
            this.tableState.selectedData = []
            this.tableState.numberOfPages = Math.ceil(1)
        },

        async getTrackEventsGrid(agencyId: string) {
            try {
                this.tableState.isLoading = true
                const response = await this.gridCollectionStore.getGrid("trackEvents")
                await this.getTrackEvents(agencyId)

                return response
            } finally {
                this.tableState.isLoading = false
            }
        },

        async updateTrackEventsGrid(agencyId: string) {
            try {
                this.tableState.isLoading = true
                if (!this.gridCollectionStore.grid) return
                this.gridCollectionStore.grid.filtersGroups = this.gridCollectionStore.filtersGroups || []

                const response = await this.gridCollectionStore.updateGrid("trackEvents", this.gridCollectionStore.grid)
                this.gridCollectionStore.gridResponse = response
                this.gridCollectionStore.grid = this.gridCollectionStore.gridResponse.grid

                await this.getTrackEvents(agencyId)
            } finally {
                this.tableState.isLoading = false
            }
        },
    },
})
