<template>
    <div class="flex flex-col w-full gap-y-6">
        <div class="flex gap-x-3 items-center">
            <span class="text-md text-gray-900">Do Not Disturb</span>
            <InputSwitch v-model="workflowStore.doNotDisturb.isEnabled" @change="enable"></InputSwitch>
        </div>

        <div v-if="workflowStore.doNotDisturb.isEnabled" class="flex w-full gap-x-4 gap-y-2 flex-grow items-center">
            <GgmsCalendar
                v-model="workflowStore.doNotDisturb.startDate"
                position="below"
                :showTime="false"
                :showDate="true"
                placeholder="Select Start Date"
                :inline="false"
                :iconStart="CalendarIcon"
                @input="updateDate"
                class="flex-grow"
            />
            <span> - </span>
            <GgmsCalendar
                v-model="workflowStore.doNotDisturb.endDate"
                position="below"
                placeholder="Select End Date"
                :showTime="false"
                :showDate="true"
                :inline="false"
                :iconStart="CalendarIcon"
                @input="updateDate"
                class="flex-grow"
            />
        </div>

        <div v-if="workflowStore.doNotDisturb.isEnabled" class="flex w-full flex-grow">
            <GgmsButton
                :style-type="selectedPeriod === 1 ? 'primary' : 'secondary'"
                :classes="['px-[7px] h-full text-sm rounded-r-none border-r-0 focus:ring-0  w-full']"
                @click="selectPeriod(1)"
                class="flex-grow"
                >1 Month</GgmsButton
            >
            <GgmsButton
                :style-type="selectedPeriod === 3 ? 'primary' : 'secondary'"
                :classes="['px-[7px] h-full text-sm  rounded-none border-r-0 focus:ring-0 w-full']"
                @click="selectPeriod(3)"
                class="flex-grow"
                >3 Months</GgmsButton
            >
            <GgmsButton
                :style-type="selectedPeriod === 6 ? 'primary' : 'secondary'"
                :classes="['px-[7px] h-full text-sm rounded-none border-r-0 focus:ring-0 w-full']"
                @click="selectPeriod(6)"
                class="flex-grow"
                >6 Months</GgmsButton
            >
            <GgmsButton
                :style-type="selectedPeriod === 12 ? 'primary' : 'secondary'"
                :classes="['px-[7px] h-full text-sm rounded-bl-none rounded-tl-none focus:ring-0 w-full']"
                @click="selectPeriod(12)"
                class="flex-grow"
                >12 Months</GgmsButton
            >
        </div>
    </div>
</template>
<script setup lang="ts">
import { onMounted, ref } from "vue"
import { useWorkflowStore } from "@/stores/workflow"
import { CalendarIcon } from "@heroicons/vue/outline"
import { formatDateToIso, getNewDate } from "@/shared/utils/helpers"
import GgmsButton from "@/components/GgmsButton.vue"
import GgmsCalendar from "@/components/GgmsCalendar.vue"

const workflowStore = useWorkflowStore()
const selectedPeriod = ref(0)
const error = ref("")

function selectPeriod(period: number) {
    selectedPeriod.value = period
    workflowStore.doNotDisturb.startDate = new Date()
    workflowStore.doNotDisturb.endDate = new Date()
    workflowStore.doNotDisturb.endDate.setMonth(workflowStore.doNotDisturb.startDate.getMonth() + period)
    workflowStore.doNotDisturb.startDate = formatDateToIso(workflowStore.doNotDisturb.startDate)
    workflowStore.doNotDisturb.endDate = formatDateToIso(workflowStore.doNotDisturb.endDate)
}

function updateDate() {
    if (workflowStore.doNotDisturb.startDate && workflowStore.doNotDisturb.endDate) {
        if (workflowStore.doNotDisturb.startDate > workflowStore.doNotDisturb.endDate) {
            error.value = "End date can't be smaller than start date"
        }
        const end = getNewDate(workflowStore.doNotDisturb.endDate)
        const start = getNewDate(workflowStore.doNotDisturb.startDate)

        if (end.getDate() === start.getDate()) {
            const yearDiff = end.getFullYear() - start.getFullYear()
            if (yearDiff > 0) {
                selectedPeriod.value = yearDiff * 12 + end.getMonth() - start.getMonth()
            } else {
                selectedPeriod.value = end.getMonth() - start.getMonth()
            }
        } else {
            selectedPeriod.value = 0
        }
    }
}

function enable() {
    if (workflowStore.doNotDisturb.isEnabled) {
        workflowStore.doNotDisturb.startDate = new Date()
        workflowStore.doNotDisturb.endDate = new Date()
        selectedPeriod.value = 1
        workflowStore.doNotDisturb.endDate.setMonth(workflowStore.doNotDisturb.startDate.getMonth() + 1)
    }
}

onMounted(() => updateDate())
</script>
