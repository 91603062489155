import { MlsFeed } from "@/shared/models/mls-feed"
import { TableState } from "@/shared/models/table"
import { MlsFeedService } from "@/shared/services/mls-feed"
import { defineStore } from "pinia"

export const useMlsFeedStore = defineStore("mlsFeed", {
    state: () => ({
        mlsFeeds: [] as MlsFeed[],
        mlsFeedService: new MlsFeedService(),
    }),
    actions: {
        async getMlsFeeds() {
            const res = await this.mlsFeedService.getMlsFeeds()
            this.mlsFeeds = res.data
        },
    },
})
