import { defineStore } from "pinia"
import { GridService } from "@/shared/services/grid"
import { CollectionService } from "@/shared/services/collection"
import { ToastService } from "@/shared/services/toast"
import { TableState } from "@/shared/models/table"
import { Collection, CollectionResponse } from "@/shared/models/collection"
import { Grid, GridResponse } from "@/shared/models/grid"
import { Filter, FiltersGroup } from "@/shared/models/filter"
import { useConfigStore } from "@/stores/config"
import { Prototype } from "@/shared/models/config"
import { Attribute } from "@/shared/models/attributes"

export const useGridCollectionStore = defineStore("grid-collection", {
    state: () => ({
        gridService: new GridService(),
        collectionService: new CollectionService(),
        toastService: new ToastService(),
        grids: [] as Grid[],
        grid: {} as Grid,
        gridResponse: {} as GridResponse,
        totalGrids: 0,
        collectionsResponse: {} as CollectionResponse,
        selectedTabId: "",
        filterSidebarCurrentStep: "attributeList" as "attributeList" | "filterList" | "filterRule" | null,
        collectionType: "",
        collectionTypeHistory: [] as string[],
        selectedFilterGroup: 0,
        isFilterSidebarVisible: false,
        filtersGroups: [] as FiltersGroup[],
        prototypes: [] as Prototype[],
        ids: "",
        selectedData: [] as { id: string; name: string }[],
        searchTerm: "",

        collectionTableState: {
            data: [],
            currentPage: 0,
            pageLength: 50,
            numberOfPages: 1,
            total: 0,
            isLoading: false,
            type: undefined,
            ownerId: "",
        } as TableState<Collection>,

        configStore: useConfigStore(),
    }),
    actions: {
        // grids actions
        async getGrids(type: string, typeName?: string) {
            const response = await this.gridService.getGrids(type, typeName)
            this.grids = response.data
            this.totalGrids = response.total
        },
        async getGrid(type: string) {
            const response = await this.gridService.getGrid(type)
            this.grid = response.data[0]
            this.filtersGroups = this.grid.filtersGroups || []
            return response
        },
        async updateGrid(type: string, newGrid: Grid) {
            return await this.gridService.updateGrid(type, newGrid)
        },
        // collections actions
        async getCollections(contactId?: string) {
            try {
                this.collectionTableState.isLoading = true
                const queryParams: { [key: string]: string | number | undefined } = {
                    start: this.collectionTableState.currentPage,
                    length: this.collectionTableState.pageLength,
                    column: this.collectionTableState.column,
                    order: this.collectionTableState.order,
                    typeName: this.collectionTableState.type,
                    ownerId: this.collectionTableState.ownerId || undefined,
                    search: this.collectionTableState.search || undefined,
                    contactId: contactId || undefined,
                }

                const response = await this.collectionService.getCollections(queryParams)
                this.collectionsResponse = response
                this.collectionTableState.data = this.collectionsResponse.data
                this.collectionTableState.total = response.total
                this.collectionTableState.numberOfPages = Math.ceil(
                    response.total / this.collectionTableState.pageLength
                )
            } finally {
                this.collectionTableState.isLoading = false
            }
        },

        async createCollection(data: Partial<Collection>) {
            try {
                this.collectionTableState.isLoading = true
                const { collection } = await this.collectionService.createCollection(data)
                this.collectionTableState.data.unshift(collection)

                this.toastService.addToast({
                    type: "success",
                    message: "Collection was successfully created",
                })
                return collection
            } catch (e) {
                const error = e as Error
                this.toastService.addToast({
                    type: "error",
                    message: error.message,
                })
            } finally {
                this.collectionTableState.isLoading = false
            }
        },

        async updateCollection(collectionId: string, data: Partial<Collection>) {
            try {
                this.collectionTableState.isLoading = true
                const { collection } = await this.collectionService.updateCollection(collectionId, data)
                const index = this.collectionTableState.data.findIndex((collection) => collection._id === collectionId)
                if (index === -1) return
                this.collectionTableState.data[index] = collection

                this.toastService.addToast({
                    type: "success",
                    message: "Collection was successfully updated",
                })
            } catch (e) {
                const error = e as Error
                this.toastService.addToast({
                    type: "error",
                    message: error.message,
                })
            } finally {
                this.collectionTableState.isLoading = false
            }
        },

        async bulkUpdateCollections(data: Partial<Collection>[], contactId?: string) {
            try {
                this.collectionTableState.isLoading = true
                await this.collectionService.bulkUpdateCollections(data, contactId)
            } finally {
                this.collectionTableState.isLoading = false
            }
        },

        async deleteCollection(collectionId: string) {
            try {
                this.collectionTableState.isLoading = true
                const response: { collection: Collection } = await this.collectionService.deleteOne(
                    `collections/${collectionId}`
                )
                this.collectionTableState.data = this.collectionTableState.data.filter(
                    (collection) => collection._id !== response.collection._id
                )
            } catch (e) {
                const error = e as Error
                this.toastService.addToast({
                    type: "error",
                    message: error.message,
                })
            } finally {
                this.collectionTableState.isLoading = false
            }
        },
        // filter sidebar actions
        getPrototypesByPage(pageName: string) {
            this.prototypes = []
            let attributes
            let allAttributes = [] as Attribute[]
            let prototype = {} as Prototype

            switch (pageName) {
                case "contacts":
                    attributes = this.configStore.prototypes.find((prototype) => prototype.typeName === "contact")
                    break
                case "tasks":
                    attributes = this.configStore.prototypes.find((prototype) => prototype.typeName === "task")
                    break
                case "agents":
                    attributes = this.configStore.prototypes.find((prototype) => prototype.typeName === "agent")
                    break
                case "forms":
                    attributes = this.configStore.prototypes.find((prototype) => prototype.typeName === "form")
                    break
                case "templates":
                    attributes = this.configStore.prototypes.find((prototype) => prototype.typeName === "template")
                    break
                case "jobs":
                    attributes = this.configStore.prototypes.find((prototype) => prototype.typeName === "job")
                    break
                case "inbox":
                    this.configStore.prototypes
                        .filter((prototype) => ["email", "sms", "call"].includes(prototype.typeName))
                        .map((prototype) => (allAttributes = allAttributes.concat(prototype.attributes)))
                    attributes = {
                        ...this.configStore.prototypes.find((prototype) => prototype.typeName === "email"),
                        attributes: allAttributes,
                        typeName: "inbox",
                        displayName: "Inbox",
                    } as Prototype
                    break
                case "origins":
                    attributes = this.configStore.prototypes.find((prototype) => prototype.typeName === "origin")
                    break
                case "tags":
                    attributes = this.configStore.prototypes.find((prototype) => prototype.typeName === "tag")
                    break
                case "logs":
                    attributes = this.configStore.prototypes.find((prototype) => prototype.typeName === "log")
                    break
                case "trackEvents":
                    attributes = this.configStore.prototypes.find((prototype) => prototype.typeName === "trackEvent")
                    break
                case "workflows":
                    attributes = this.configStore.prototypes.find((prototype) => prototype.typeName === "workflow")
                    break
                case "listings":
                    prototype = this.configStore.prototypes.find(
                        (prototype) => prototype.typeName === "listing"
                    ) as Prototype
                    if (prototype) {
                        attributes = {
                            ...prototype,
                            attributes: prototype.attributes.filter((attribute) => {
                                return (
                                    attribute.displayName !== "MLS #" &&
                                    attribute.displayName !== "Thumbnail" &&
                                    attribute.displayName !== "Featured On"
                                )
                            }),
                        }
                    }
                    break
                case "contact-listings":
                    prototype = this.configStore.prototypes.find(
                        (prototype) => prototype.typeName === "contact-listing"
                    ) as Prototype
                    if (prototype) {
                        attributes = {
                            ...prototype,
                            attributes: prototype.attributes.filter((attribute) => {
                                return (
                                    attribute.displayName !== "MLS #" &&
                                    attribute.displayName !== "Thumbnail" &&
                                    attribute.displayName !== "Featured On"
                                )
                            }),
                        }
                    }
                    break
                case "invoice":
                    attributes = this.configStore.prototypes.find((prototype) => prototype.typeName === "invoice")
                    break
                case "calls":
                    attributes = this.configStore.prototypes.find((prototype) => prototype.typeName === "call")
                    break
                case "landing-pages":
                    attributes = this.configStore.prototypes.find((prototype) => prototype.typeName === "landingPage")
                    break
                default:
                    break
            }
            if (attributes) {
                this.prototypes.push(attributes)
            }
        },

        addFilterToGroup(filter: Filter) {
            const fieldMapping: { [key: string]: string } = {
                phones: "phones.number",
                emails: "emails.emailAddress",
            }

            if (
                this.prototypes.some((prototype) =>
                    prototype.attributes.some((attribute) => attribute.name === filter.field && !attribute.isRoot)
                )
            ) {
                filter.field = `attributes.${filter.field}`
            }

            if (Object.prototype.hasOwnProperty.call(fieldMapping, filter.field)) {
                filter.field = fieldMapping[filter.field]
            }

            if (!this.filtersGroups?.[this.selectedFilterGroup]) {
                this.filtersGroups.push({ filters: [filter] })
                return
            }
            this.filtersGroups[this.selectedFilterGroup].filters.push(filter)
        },

        editFilterGroup(filter: Filter, filterIndex: number) {
            // TODO - we need to discuss about object type
            if (filter.field === "phones") {
                filter.field = "phones.number"
            } else if (filter.field === "emails") {
                filter.field = "emails.emailAddress"
            }

            this.filtersGroups[this.selectedFilterGroup].filters[filterIndex] = filter
        },

        removeFilter(groupIndex: number, filterIndex: number) {
            this.filtersGroups[groupIndex].filters.splice(filterIndex, 1)
        },

        removeGroup(index: number) {
            this.filtersGroups.splice(index, 1)
        },

        clearFilters() {
            this.filtersGroups = []
            this.grid.filtersGroups = []
        },

        setFilterDefaultCurrentStep() {
            this.filterSidebarCurrentStep = "attributeList"
        },

        removeEmptyFilterGroupsForField(field: string, selectedValue: string[]) {
            if (selectedValue.length === 0) {
                this.filtersGroups = this.filtersGroups.filter((filterGroup) => {
                    return !(
                        filterGroup.filters.some((filter) => filter.field === field) &&
                        filterGroup.filters.every((filter) =>
                            Array.isArray(filter.value) ? filter.value.length === 0 : false
                        )
                    )
                })
            }
        },
    },
})
