<template>
    <template v-if="isDataFetched">
        <!-- Phone on call bar -->
        <PhoneOnCallBar
            v-if="isPhoneTopBarVisible"
            @onCloseCall="closeCall"
            @onOpenSidebar="openPhoneSidebar"
        ></PhoneOnCallBar>

        <!-- Task session bar -->
        <GgmsTaskSessionBar v-if="isTaskSessionStarted" />

        <!-- Static sidebar for desktop -->
        <div
            :class="[
                'flex flex-col fixed h-full transition-all duration-500 ease-in-out',
                isCollapsed ? 'w-16' : 'w-64',
            ]"
            v-if="!isMobileScreen"
            style="z-index: 40"
        >
            <div class="flex flex-col justify-between h-full">
                <GgmsNavigation :items="navigation" :collapsed="isCollapsed" @menuItemClick="handleMenuClick" />

                <!-- Collapse/Expand Arrow Container -->
                <div
                    @click="toggleSidebar"
                    class="w-6 h-6 bg-white border rounded-full flex justify-center items-center cursor-pointer transition-transform duration-300 ease-in-out hover:bg-primary-color-600 hover:text-white focus:ring focus:ring-primary-color absolute bottom-36 -right-3 group"
                    :class="{ 'rotate-180': isCollapsed }"
                    role="button"
                    aria-label="Collapse Sidebar"
                >
                    <component
                        :is="ArrowIcon"
                        class="w-4 h-4 text-primary-color-600 transition-transform duration-300 ease-in-out group-hover:text-white"
                        :class="{ 'transform rotate-180': isCollapsed }"
                    />
                </div>
            </div>
        </div>

        <!-- Main content area -->
        <div
            :class="[
                isCollapsed ? 'md:pl-16' : isMobileScreen ? '' : 'md:pl-64',
                'flex flex-col flex-1 transition-all duration-300 ease-in-out z-50',
            ]"
        >
            <!-- Main top bar -->
            <div class="sticky z-40 flex-shrink-0 flex h-fit bg-white shadow" :class="mainTopBarTopClass">
                <!-- Mobile header and sidebar -->
                <MobileSidebarAndHeader
                    v-if="isMobileScreen"
                    :navigation="navigation"
                    :isTestMode="isTestMode"
                    @openPhoneSidebar="openPhoneSidebar"
                    @openAddContactModal="openAddContactModal"
                    @openNotificationSidebar="openNotificationSidebar"
                />

                <!-- Desktop header -->
                <div v-else class="flex gap-x-4 w-full justify-end pl-2 pr-6">
                    <!-- Test Mode Indicator -->
                    <div v-if="isTestMode" class="flex items-center">
                        <GgmsTooltip
                            text="The system is in Test Mode. Communications are restricted until going live."
                            :icon="InformationCircleIcon"
                            iconClass="w-8 h-8 cursor-pointer"
                            iconColorClass="text-orange-300 hover:text-orange-500"
                            @on-button-click="redirectToCompanySettings"
                        />
                    </div>

                    <MenuActionItems
                        class="h-16"
                        @openPhoneSidebar="openPhoneSidebar"
                        @openAddContactModal="openAddContactModal"
                        @openNotificationSidebar="openNotificationSidebar"
                        @openNewEmail="openNewEmailModal"
                        @openNewMessage="openNewMessageModal"
                    />
                </div>
            </div>

            <!-- Main content -->
            <main class="flex-1">
                <div class="overflow-auto">
                    <slot />
                </div>
            </main>
        </div>

        <!-- Modals and sidebars -->
        <AddContactModal
            :open="isAddContactModalOpen"
            :contact="phoneCallContact"
            @closeModal="closeAddContactModal()"
        />
        <GgmsModal
            :open="isNewMessageModalOpen"
            bodyClass="p-0"
            headerClass="border-b border-gray-200 py-5"
            size="3xl"
            @closeModal="closeNewMessageModal"
        >
            <template #header>
                <div>New Message</div>
            </template>
            <CreatingMessage
                :textMessage="currentMessage"
                :hasContact="true"
                @saveTextMessage="closeNewMessageModal"
            ></CreatingMessage>
        </GgmsModal>

        <GgmsModal
            :open="isNewEmailModalOpen"
            bodyClass="p-0"
            headerClass="border-b border-gray-200 py-5"
            size="3xl"
            @closeModal="closeCreateEmailModal"
        >
            <template #header>
                <h1 class="text-lg font-medium text-gray-900">New Email</h1>
            </template>
            <CreatingEmail
                :message="currentEmail"
                :hasContact="true"
                @saveEmail="closeCreateEmailModal"
            ></CreatingEmail>
        </GgmsModal>
        <PhoneCallSidebar v-model="isPhoneSidebarOpen" />
        <NotificationSidebar v-model="isNotificationSidebarOpen" />
        <FilterSidebar />
        <EmailVerificationModal
            :open="isAgencyNewlyRegistered"
            @closeModal="closeEmailVerificationModal"
        ></EmailVerificationModal>
    </template>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted, watch, nextTick } from "vue"
import GgmsNavigation from "@/components/GgmsNavigation.vue"
import GgmsTaskSessionBar from "@/components/GgmsTaskSessionBar.vue"
import AddContactModal from "@/components/AddContactModal.vue"
import EmailVerificationModal from "@/components/email-verification/EmailVerificationModal.vue"
import PhoneCallSidebar from "@/components/phone-sidebar/PhoneCallSidebar.vue"
import PhoneOnCallBar from "@/components/phone-sidebar/PhoneOnCallBar.vue"
import NotificationSidebar from "@/components/notifications/NotificationsSidebar.vue"
import MobileSidebarAndHeader from "@/components/MobileSidebarAndheader.vue"
import MenuActionItems from "@/components/MenuActionItems.vue"
import FilterSidebar from "@/components/filters/FilterSidebar.vue"
import { ArrowLeftIcon, ArrowRightIcon, InformationCircleIcon } from "@heroicons/vue/outline"

import {
    UsersIcon,
    InboxInIcon,
    ClipboardCheckIcon,
    CogIcon,
    DocumentTextIcon,
    PresentationChartLineIcon,
    ClipboardListIcon,
    RefreshIcon,
    FireIcon,
    OfficeBuildingIcon,
    TemplateIcon,
} from "@heroicons/vue/outline"
import CodeSquareIcon from "@/components/icons/CodeSquareIcon.vue"

import { getUser, deleteCookie, getCookieValue, hasUserPermission } from "@/shared/utils/helpers"
import { useTaskStore } from "@/stores/task"
import { useTagStore } from "@/stores/tag"
import { useAuthStore } from "@/stores/auth"
import { useAgencyStore } from "@/stores/agency"
import { usePhoneCallStore } from "@/stores/phone-call"
import { useConfigStore } from "@/stores/config"
import { useAgentsStore } from "@/stores/agents"
import { useSocketStore } from "@/stores/socket"
import { useContactsStore } from "@/stores/contacts"
import { useOriginStore } from "@/stores/origin"
import { PhoneCall } from "@/shared/models/phone-call"
import { ResourceTypeName, Role } from "@/shared/models/role"
import router from "@/router"
import CreatingMessage from "@/components/profile-details/middle-panel/CreatingMessage.vue"
import CreatingEmail from "@/components/profile-details/middle-panel/CreatingEmail.vue"
import GgmsModal from "@/components/GgmsModal.vue"
import GgmsTooltip from "@/components/GgmsTooltip.vue"

const taskStore = useTaskStore()
const tagStore = useTagStore()
const authStore = useAuthStore()
const agencyStore = useAgencyStore()
const phoneCallStore = usePhoneCallStore()
const configStore = useConfigStore()
const agentsStore = useAgentsStore()
const socketStore = useSocketStore()
const contactStore = useContactsStore()
const originStore = useOriginStore()

let navigation = [
    // { name: "Dashboard", icon: HomeIcon, path: "/dashboard" },
    { name: "Contacts", icon: UsersIcon, path: "/contacts" },
    { name: "Listings", icon: OfficeBuildingIcon, path: "/listings" },
    { name: "Inbox", icon: InboxInIcon, path: "/inbox" },
    // { name: "Deals", icon: TagIcon, path: "/deals" },
    { name: "Tasks", icon: ClipboardCheckIcon, path: "/tasks" },
    // { name: "Reports", icon: ChartBarIcon, path: "/reports" },
    {
        name: "Templates",
        icon: DocumentTextIcon,
        path: "/templates",
    },
    {
        name: "Workflows",
        icon: FireIcon,
        path: "/workflows",
    },
    { name: "Forms", icon: ClipboardListIcon, path: "/forms" },
    {
        name: "Landing Pages",
        icon: TemplateIcon,
        path: "/landing-pages",
    },
    {
        name: "Developers",
        icon: CodeSquareIcon,
        children: [
            {
                name: "Analytics Api",
                path: "/developers/analytics-api",
            },
            {
                name: "API Keys",
                path: "/developers/api-keys",
            },
            {
                name: "Website Events Beacon",
                path: "/developers/track-events",
            },
        ],
    },
    {
        name: "Settings",
        icon: CogIcon,
        children: [
            {
                name: "My Settings",
                path: "/my-settings",
            },
            {
                name: "Agency Settings",
                path: "/agency-settings",
            },
            {
                name: "Plans & Billing",
                path: "/billing",
            },
            {
                name: "Agencies",
                path: "/agencies",
            },
            {
                name: "MLS Feeds",
                path: "/mls-feeds",
            },
            {
                name: "Domains",
                path: "/domains",
            },
            {
                name: "Fields",
                path: "/fields",
            },
            {
                name: "Phone Numbers",
                path: "/phone-numbers",
            },
            {
                name: "Tags",
                path: "/tags",
            },
            {
                name: "Origins",
                path: "/origins",
            },
            {
                name: "Collections",
                path: "/collections",
            },
            {
                name: "Agency Theme",
                path: "/themes",
            },
            {
                name: "Integrations",
                path: "/integrations",
            },
        ],
    },
    { name: "Team", icon: UsersIcon, path: "/team" },
    // { name: "Audit", icon: PresentationChartLineIcon, path: "/audit" },
    { name: "Event Logs", icon: PresentationChartLineIcon, path: "/logs" },
    { name: "Jobs", icon: RefreshIcon, path: "/jobs" },
]

// Collapse/Expand sidebar
const isCollapsed = ref(window.screen.width <= 768)
const ArrowIcon = computed(() => (isCollapsed.value ? ArrowRightIcon : ArrowLeftIcon))

const isDataFetched = ref(false)
const isPhoneSidebarOpen = ref(false)
const isNotificationSidebarOpen = ref(false)
const isAgencyNewlyRegistered = ref(false)
const searchTerm = ref("")

//--------send email------------------
const isNewEmailModalOpen = ref(false)
const currentEmail = ref()

//--------send text message------------------
const isNewMessageModalOpen = ref(false)
const currentMessage = ref()

const isPhoneCallInProgress = computed(() => ["calling", "ongoing"].includes(phoneCallStore.phoneCallState))
const isPhoneTopBarVisible = computed(() => isPhoneCallInProgress.value && !isPhoneSidebarOpen.value)
const mainTopBarTopClass = computed(() => (isPhoneTopBarVisible.value ? "top-[66px]" : "top-0"))
const isAddContactModalOpen = computed(() => contactStore.isAddContactModalOpen)
const phoneCallContact = computed(() => phoneCallStore.currentPhoneCall?.contacts?.[0])
const currentUser = computed(() => authStore.currentUser)
const isTaskSessionStarted = computed(() => taskStore.isActiveTaskSession)

const isTestMode = computed(() => agencyStore.agency?.isTestMode)

const isMobileScreen = computed(() => window.screen.width < 768)

// Toggle sidebar collapse/expand
function toggleSidebar() {
    isCollapsed.value = !isCollapsed.value
}

// Handle menu item click
function handleMenuClick(menuItem: any) {
    const currentRoute = router.currentRoute.value.path
    const isSubpage = menuItem.children?.some((child: { name: string; path: string }) => child.path === currentRoute)

    if (isSubpage) {
        isCollapsed.value = false

        return
    }

    if (menuItem.name === "Settings" || menuItem.name === "Developers") {
        isCollapsed.value = false

        nextTick(() => {
            const menuItemElement = document.getElementsByName(menuItem.name)[0]
            if (menuItemElement) {
                menuItemElement.click()
            }
        })
    }
}

function redirectToCompanySettings() {
    router.push("/agency-settings")
}

async function openAddContactModal() {
    tagStore.tableState.typeName = "contact"
    await originStore.getAllOrigins()
    contactStore.isAddContactModalOpen = true
}

function closeAddContactModal() {
    contactStore.isAddContactModalOpen = false
    phoneCallStore.currentPhoneCall = {} as Partial<PhoneCall>
}

function openPhoneSidebar() {
    if (phoneCallStore.phoneCallState === "idle") {
        phoneCallStore.getCalls()
        tagStore.getPhoneCallTags()
    }
    isPhoneSidebarOpen.value = true
}

function closeCall() {
    phoneCallStore.phoneCallState = "finished"
    phoneCallStore.disconnectTwilio()
}

function openNotificationSidebar() {
    isNotificationSidebarOpen.value = true
}

function closeEmailVerificationModal() {
    isAgencyNewlyRegistered.value = false
    deleteCookie("isAgencyNewlyRegistered")
}

function checkIfAgencyIsNewlyRegistered() {
    const isAgencyNewlyRegisteredCookie = getCookieValue("isAgencyNewlyRegistered")

    // if the email is confirmed, emailConfirmation will be undefined and we explicitly check the value for false
    isAgencyNewlyRegistered.value =
        currentUser.value?.emailConfirmation?.confirmed === false && isAgencyNewlyRegisteredCookie === "true"
}

function closeNewMessageModal() {
    isNewMessageModalOpen.value = false
}

function closeCreateEmailModal() {
    isNewEmailModalOpen.value = false
}

function openNewMessageModal() {
    isNewMessageModalOpen.value = true
}

function openNewEmailModal() {
    isNewEmailModalOpen.value = true
}

onMounted(async () => {
    await Promise.all([
        agencyStore.loadAgency(),
        agencyStore.getAvailableAgencies(),
        taskStore.getActiveTaskSession(),
        configStore.getConfig(),
        agentsStore.getMe(),
        tagStore.getCachedTags(),
        originStore.getCachedOrigins(),
        socketStore.connectSocket(),
        socketStore.connectJobs(),
        agentsStore.loadAgents(),
    ])
    const user = getUser()
    authStore.currentUser = user
    isDataFetched.value = true
    // filter out those navigation items that doesn't have permission
    navigation = navigation.filter((item) => hasUserPermission(user?.role as Role, item.name as ResourceTypeName))
    checkIfAgencyIsNewlyRegistered()

    // Set up a periodic check for socket connection
    setInterval(() => {
        if (!socketStore.isConnected) {
            console.log("Socket disconnected, attempting to reconnect...")
            socketStore.reconnectSocket()
        }
    }, 30000) // Check every 30 seconds
})

// Add route change handler to ensure socket is connected when navigating
watch(
    () => router.currentRoute.value.path,
    () => {
        if (!socketStore.isConnected) {
            console.log("Reconnecting socket on route change...")
            socketStore.reconnectSocket()
        }
    }
)

watch(
    () => contactStore.isAddContactModalOpen,
    (value) => {
        if (!value) {
            return
        }
        isPhoneSidebarOpen.value = false
        phoneCallStore.phoneCallState = "idle"
        isNotificationSidebarOpen.value = false
    }
)
</script>
