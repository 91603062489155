<template>
    <div class="flex flex-col">
        <p class="text-lg contacting-7 font-medium text-black pb-6">Date when workflow should trigger</p>
        <div class="flex flex-col gap-y-5">
            <GgmsCalendar
                v-model="workflowStore.modelValue.date"
                position="below"
                label="Select day"
                minDate="today"
                :showTime="false"
                :showDate="true"
                :iconStart="CalendarIcon"
            >
            </GgmsCalendar>
            <GgmsCalendar
                v-model="workflowStore.modelValue.time"
                position="below"
                label="Select time"
                :minDate="minHour"
                :showTime="true"
                :showDate="false"
                :iconStart="ClockIcon"
            >
            </GgmsCalendar>
        </div>
    </div>
</template>
<script setup lang="ts">
import { computed, watch, ref } from "vue"
import { CalendarIcon, ClockIcon } from "@heroicons/vue/outline"
import { useWorkflowStore } from "@/stores/workflow"
import { dateTimeConverter, isEarlierToday, isToday } from "@/shared/utils/helpers"
import GgmsCalendar from "@/components/GgmsCalendar.vue"

const workflowStore = useWorkflowStore()
const isSelectedDateToday = ref(false)
const minHour = computed(() => (isSelectedDateToday.value ? "now" : "today"))

watch(
    () => [workflowStore.modelValue?.date, workflowStore.modelValue?.time],
    () => {
        isSelectedDateToday.value = isToday(new Date(workflowStore.modelValue?.date))
        if (
            workflowStore.modelValue?.date &&
            workflowStore.modelValue?.time &&
            isEarlierToday(workflowStore.modelValue?.date, workflowStore.modelValue?.time)
        ) {
            const newDate = new Date()
            let hours = newDate.getHours()
            const minutes = String(newDate.getMinutes()).padStart(2, "0")
            const period = hours >= 12 ? "PM" : "AM"
            hours = hours % 12 || 12 // Convert to 12-hour format, 0 becomes 12
            const hoursStr = String(hours).padStart(2, "0")
            workflowStore.modelValue.time = `${hoursStr}:${minutes} ${period}`
        }
        //convert time and date into ISO date
        if (workflowStore.modelValue?.date && workflowStore.modelValue?.time) {
            workflowStore.modelValue.dateTime = dateTimeConverter(
                workflowStore.modelValue.time,
                workflowStore.modelValue.date
            )
        }
        if (workflowStore.modelValue?.date) {
            workflowStore.areButtonDisabled = false
        }
    }
)
</script>
