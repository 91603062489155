<template>
    <div class="flex flex-col gap-y-5">
        <div class="flex max-sm:flex-wrap gap-2">
            <ActionCard
                title="Delay"
                @click="
                    changeAction({
                        title: 'Configure delay...',
                        sidebarComponent: DelaySidebarComponent,
                        type: ActionNodeType.delay,
                    })
                "
            >
                <ClockIcon class="h-5 w-5 text-gray-900"> </ClockIcon>
            </ActionCard>
            <ActionCard title="Branch" :disabled="workflowStore.disabledBranches" @click="enterBranches">
                <MergeIcon :class="iconStyle"> </MergeIcon>
            </ActionCard>
            <!--            <ActionCard title="Go to workflow" @click="changeAction({ title: 'Go to workflow...' })">-->
            <!--                <ArrowCircleRightIcon class="h-5 w-5 text-gray-900"> </ArrowCircleRightIcon>-->
            <!--            </ActionCard>-->
            <!--            <ActionCard title="Go to action" @click="changeAction({ title: 'Go to action...' })">-->
            <!--                <ArrowCircleRightIcon class="h-5 w-5 text-gray-900"> </ArrowCircleRightIcon>-->
            <!--            </ActionCard>-->
        </div>
        <GgmsInput
            input-class="bg-gray-100 border-0"
            v-model="searchTerm"
            :small="true"
            :iconEnd="SearchIcon"
            placeholder="Search"
        ></GgmsInput>
        <div class="flex flex-col">
            <div v-if="filteredCommunicationItems.length">
                <div class="flex gap-x-2 cursor-pointer" @click="isOpenCommunication = !isOpenCommunication">
                    <span class="text-base leading-6 font-semibold text-gray-700">Communications</span>
                    <ArrowDown v-if="isOpenCommunication" class="fill-gray-700"></ArrowDown>
                    <ArrowDown v-else class="rotate-180 fill-gray-700"></ArrowDown>
                </div>
                <transition>
                    <div v-if="isOpenCommunication" class="flex flex-col pt-3">
                        <ActionItem
                            v-for="item in filteredCommunicationItems"
                            :key="item.title"
                            :title="item.title"
                            :description="item.description"
                            :icon="item.icon"
                            :useFill="item.useFill ?? false"
                            :useStroke="item.useStroke ?? false"
                            @click="changeAction(item)"
                        ></ActionItem>
                    </div>
                </transition>
            </div>
            <div v-if="filteredCrmItems.length">
                <div class="flex gap-x-2 mt-6 cursor-pointer" @click="isOpenCrm = !isOpenCrm">
                    <span class="text-base leading-6 font-semibold text-gray-700">CRM</span>
                    <ArrowDown v-if="isOpenCrm" class="fill-gray-700"></ArrowDown>
                    <ArrowDown v-else class="rotate-180 fill-gray-700"></ArrowDown>
                </div>
                <transition>
                    <div v-if="isOpenCrm" class="flex flex-col pt-3">
                        <ActionItem
                            v-for="item in filteredCrmItems"
                            :key="item.title"
                            :title="item.title"
                            :description="item.description"
                            :icon="item.icon"
                            :useFill="item.useFill ?? false"
                            @click="changeAction(item)"
                        ></ActionItem>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { Component, computed, ref } from "vue"
import {
    AdjustmentsIcon,
    AnnotationIcon,
    ChatAltIcon,
    ClipboardCheckIcon,
    ClipboardListIcon,
    ClockIcon,
    FireIcon,
    MailIcon,
    OfficeBuildingIcon,
    SearchIcon,
    TagIcon,
    TrashIcon,
    UserCircleIcon,
} from "@heroicons/vue/outline"
import { useWorkflowStore } from "@/stores/workflow"
import { ActionNodeType } from "@/shared/models/workflow"
import ArrowDown from "@/components/icons/ArrowDown.vue"
import ActionCard from "@/components/workflows/sidebar/action/ActionCard.vue"
import ActionItem from "@/components/workflows/sidebar/action/ActionItem.vue"
import MergeIcon from "@/components/icons/MergeIcon.vue"
import GgmsInput from "@/components/GgmsInput.vue"
import NoteComponent from "@/components/workflows/sidebar/action/crm/NoteComponent.vue"
import PropertyValueComponent from "@/components/workflows/sidebar/action/crm/PropertyValueComponent.vue"
import ContactDistributionComponent from "@/components/workflows/sidebar/action/crm/ContactDistributionComponent.vue"
import SendEmailComponent from "@/components/workflows/sidebar/action/communications/SendEmailComponent.vue"
import BranchSidebarComponent from "@/components/workflows/sidebar/BranchSidebarComponent.vue"
import DelaySidebarComponent from "@/components/workflows/sidebar/DelaySidebarComponent.vue"
import SendTextMessageComponent from "@/components/workflows/sidebar/action/communications/SendTextMessageComponent.vue"
import ApplyTagsComponent from "@/components/workflows/sidebar/action/crm/ApplyTagsComponent.vue"
import EnrollOrUnenrollWorkflowComponent from "@/components/workflows/sidebar/action/crm/EnrollOrUnenrollWorkflowComponent.vue"
import DeleteContactsComponent from "@/components/workflows/sidebar/action/crm/DeleteContactsComponent.vue"
import CreateTaskSidebarComponent from "@/components/workflows/sidebar/action/crm/CreateTaskSidebarComponent.vue"
import DNDIcon from "@/components/icons/DNDIcon.vue"
import MarketingPreferencesComponent from "@/components/workflows/sidebar/action/communications/MarketingPreferencesComponent.vue"
import DoNotDisturbComponent from "@/components/workflows/sidebar/action/communications/DoNotDisturbComponent.vue"

const workflowStore = useWorkflowStore()

const emit = defineEmits(["changeAction"])

const isOpenCommunication = ref(true)
const isOpenCrm = ref(true)

const communicationItems = [
    {
        title: "Do Not Disturb",
        description: "Send an email to any of your team members",
        sidebarComponent: DoNotDisturbComponent,
        icon: DNDIcon,
        useStroke: true,
    },
    {
        title: "Marketing Preferences",
        description: "Description of item",
        sidebarComponent: MarketingPreferencesComponent,
        icon: AdjustmentsIcon,
    },
    {
        title: "Send email",
        description: "Send an email to contacts",
        sidebarComponent: SendEmailComponent,
        icon: MailIcon,
    },
    {
        title: "Send text message",
        description: "Send a text message to contacts",
        sidebarComponent: SendTextMessageComponent,
        icon: ChatAltIcon,
    },
    // {
    //     title: "Send in-app notification (Internal)",
    //     description: "Send a notification to any of your team members",
    //     sidebarComponent: BranchSidebarComponent,
    // },
]

const crmItems = [
    {
        title: "Add or remove tags",
        description: "Add, remove, edit",
        sidebarComponent: ApplyTagsComponent,
        icon: TagIcon,
    },
    {
        title: "Contact Assignment",
        description: "Assigning, Availability",
        sidebarComponent: ContactDistributionComponent,
        icon: UserCircleIcon,
    },
    {
        title: "Create task",
        description: "Create a task for a team member",
        sidebarComponent: CreateTaskSidebarComponent,
        icon: ClipboardCheckIcon,
    },
    // {
    //     title: "Delete contact",
    //     description: "Description of item",
    //     sidebarComponent: DeleteContactsComponent,
    //     icon: TrashIcon,
    // },
    {
        title: "Enroll or Unenroll from workflows",
        description: "Configure action...",
        sidebarComponent: EnrollOrUnenrollWorkflowComponent,
        icon: FireIcon,
    },
    {
        title: "Note",
        description: "Description of item",
        sidebarComponent: NoteComponent,
        icon: AnnotationIcon,
    },
    {
        title: "Property Alert(s)",
        description: "Description of item",
        sidebarComponent: BranchSidebarComponent,
        icon: OfficeBuildingIcon,
    },
    {
        title: "Update Contact Record",
        description: "Set, clear, copy",
        sidebarComponent: PropertyValueComponent,
        icon: ClipboardListIcon,
    },
    // {
    //     title: "Contact owner",
    //     description: "Description of item",
    //     sidebarComponent: BranchSidebarComponent,
    // },
    // {
    //     title: "Create record",
    //     description: "Description of item",
    //     sidebarComponent: DelaySidebarComponent,
    // },
    // {
    //     title: "Suggest Property",
    //     description: "Description of item",
    //     sidebarComponent: BranchSidebarComponent,
    // },
]

const searchTerm = ref("")

const filteredCommunicationItems = computed(() =>
    communicationItems.filter((item) => item.title.toLowerCase().includes(searchTerm.value.toLowerCase()))
)

const filteredCrmItems = computed(() =>
    crmItems.filter((item) => item.title.toLowerCase().includes(searchTerm.value.toLowerCase()))
)

const iconStyle = computed(() => [workflowStore.disabledBranches ? "fill-gray-400" : "", "h-5 w-5 rotate-180"])

function enterBranches() {
    if (workflowStore.disabledBranches) {
        return
    }
    changeAction({
        title: "Configure branch...",
        sidebarComponent: BranchSidebarComponent,
        type: ActionNodeType.branch,
    })
}
function changeAction(event: { title: string; sidebarComponent?: Component; description?: string; type: string }) {
    emit("changeAction", event)
}
</script>
