<template>
    <div class="flex flex-col px-2 pb-2 gap-3 bg-gray-100 rounded-lg border border-gray-200 shadow-sm">
        <div class="flex justify-between px-2 text-gray-500"></div>
        <div class="flex flex-col gap-2 p-2 bg-white rounded-md shadow-sm">
            <div
                v-for="(filter, filterIndex) in filters"
                :key="filter.field"
                class="flex gap-2 items-center justify-between text-sm cursor-pointer hover:bg-gray-100 rounded-md px-2 py-1"
                @mouseover="setIconVisibility(filterIndex, true)"
                @mouseleave="setIconVisibility(filterIndex, false)"
            >
                <div class="flex gap-2 items-center text-sm">
                    <span class="text-gray-700 font-semibold">
                        {{ filter.displayName }}
                    </span>
                    <template v-if="filter.displayName === 'Tags'">
                        <span class="text-gray-500">in</span>
                        <div class="flex flex-wrap gap-2">
                            <GgmsChip
                                v-for="tag in getTagsByFilterIds(filter)"
                                :key="tag._id"
                                :bgColor="cachedTags[tag._id]?.backgroundColor"
                                :textColor="cachedTags[tag._id]?.foregroundColor"
                                :isRemovable="false"
                                >{{ tag.displayName }}</GgmsChip
                            >
                        </div>
                    </template>
                    <template v-else-if="filter.displayName === 'Origins'">
                        <span class="text-gray-500">in</span>
                        <div class="flex flex-wrap gap-2">
                            <GgmsChip
                                v-for="origin in getOriginsByFilterIds(filter)"
                                :key="origin._id"
                                :bgColor="cachedOrigins[origin._id]?.backgroundColor"
                                :textColor="cachedOrigins[origin._id]?.foregroundColor"
                                :isRemovable="false"
                                >{{ origin.displayName }}</GgmsChip
                            >
                        </div>
                    </template>
                    <template v-else>
                        <span v-if="filter.field !== 'doNotDisturb'" class="text-gray-500">
                            {{ displayFilterRule(filter.rule) }}
                        </span>
                        <div v-if="checkIsArray(filter.value)" class="flex gap-x-2">
                            <div v-if="filter?.rule === 'isInIds'" class="filter-value">
                                {{ displayFilterValue(filter.value[0]).slice(0, 30) + "..." }}
                            </div>
                            <div v-else-if="filter.type === 'Date'" class="flex gap-x-2 justify-center">
                                <div class="filter-value">
                                    {{
                                        displayFilterValue(
                                            filter.value[0],
                                            filter.field,
                                            filter.type === "Date",
                                            shouldFormatTime(filter.displayName)
                                        )
                                    }}
                                </div>
                                <div>-</div>
                                <div class="filter-value">
                                    {{
                                        displayFilterValue(
                                            filter.value[1],
                                            filter.field,
                                            filter.type === "Date",
                                            shouldFormatTime(filter.displayName)
                                        )
                                    }}
                                </div>
                            </div>
                            <div
                                v-else-if="filter.type === 'number' && Array.isArray(filter.value)"
                                class="flex gap-x-2"
                            >
                                <template v-if="filter.value[0] && filter.value[1]">
                                    <!-- Both values are present -->
                                    <div class="filter-value">{{ filter.value[0] }}</div>
                                    <div>-</div>
                                    <div class="filter-value">{{ filter.value[1] }}</div>
                                </template>
                                <template v-else-if="filter.value[0] && !filter.value[1]">
                                    <!-- Only the first value is present (Greater than X) -->
                                    <div class="filter-value">
                                        Greater than {{ filter.value[0] !== null ? filter.value[0] : "No value" }}
                                    </div>
                                </template>
                                <template v-else-if="!filter.value[0] && filter.value[1]">
                                    <!-- Only the second value is present (Less than Y) -->
                                    <div class="filter-value">
                                        Less than {{ filter.value[1] !== null ? filter.value[1] : "No value" }}
                                    </div>
                                </template>
                                <template v-else>
                                    <!-- No values are present -->
                                    <div class="filter-value">No range selected</div>
                                </template>
                            </div>

                            <div v-else class="flex gap-x-2">
                                <div v-for="(value, index) in filter.value" :key="index" class="filter-value">
                                    {{
                                        displayFilterValue(
                                            value,
                                            filter.field,
                                            false,
                                            false,
                                            ["type", "typeName"].includes(filter.field),
                                            filter.rule === "isIn"
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                        <div v-else-if="filter.value !== undefined && filter.value !== null" class="filter-value">
                            {{
                                displayFilterValue(
                                    Array.isArray(filter.value) ? String(filter.value[0]) : String(filter.value),
                                    filter.field,
                                    filter.type === "Date",
                                    shouldFormatTime(filter.displayName),
                                    ["type", "typeName"].includes(filter.field),
                                    filter.rule === "isIn",
                                    filter.field === "reminder"
                                )
                            }}
                        </div>
                    </template>
                </div>
                <div v-if="iconVisibility[filterIndex] && !justDisplay" class="flex gap-2 items-center">
                    <PencilIcon
                        class="h-5 w-5 text-gray-400 hover:text-gray-500"
                        @click.prevent.stop="editFilter(filter, filterIndex)"
                    />
                    <TrashIcon
                        v-if="filters.length > 1"
                        class="h-5 w-5 text-gray-400 hover:text-gray-500"
                        @click.prevent.stop="removeFilter(filterIndex)"
                    />
                </div>
            </div>
        </div>
        <div class="flex justify-between">
            <div
                v-if="!justDisplay"
                class="px-2 bg-primary-color-600 text-white rounded w-fit cursor-pointer"
                @click.stop="addNewFilterToGroup"
            >
                AND
            </div>
            <TrashIcon
                v-if="!isOnlyOneGroup && !justDisplay"
                class="h-5 w-5 text-gray-400 hover:text-gray-500 cursor-pointer"
                @click.stop="removeGroup"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue"
import GgmsChip from "@/components/GgmsChip.vue"
import { Filter } from "@/shared/models/filter"
import { displayFilterValue, displayFilterRule } from "@/shared/utils/helpers"
import { PencilIcon, TrashIcon } from "@heroicons/vue/outline"
import { useTagStore } from "@/stores/tag"
import { useOriginStore } from "@/stores/origin"

interface Props {
    groupIndex: number
    filters: Filter[]
    groupsLength: number
    justDisplay?: boolean
}

const tagStore = useTagStore()
const originStore = useOriginStore()

const props = withDefaults(defineProps<Props>(), { justDisplay: false })
const emit = defineEmits(["removeGroup", "addNewFilterToGroup", "editFilter", "removeFilter"])

const iconVisibility = ref(new Array(props.filters.length).fill(false))

const isOnlyOneGroup = computed(() => props.groupsLength === 1)
const cachedTags = computed(() => tagStore.cachedTags)
const cachedOrigins = computed(() => originStore.cachedOrigins)

function getTagsByFilterIds(filter: Filter) {
    return tagStore.tableState.data.filter((tag) => (filter.value as string[])?.includes(tag._id))
}

function getOriginsByFilterIds(filter: Filter) {
    return originStore.tableState.data.filter((origin) => (filter.value as string[])?.includes(origin._id))
}

function shouldFormatTime(displayName?: string) {
    return [
        "Due Date & Time",
        "Last Updated At",
        "Created At",
        "Last Sent Email",
        "Last Email",
        "Last Received Email",
        "Last Call Made",
        "Last Call",
        "Last Call Received",
        "Last Sent Text",
        "Last Text",
        "Last Received Text",
        "Last Communication",
        "Sent at",
        "Last Run At",
        "Last Updated At",
    ].includes(displayName ?? "")
}

function getDisplayValueForFilter(filter: Filter) {
    if (filter.field === "createdAt") {
        const options = [
            { displayName: "Last 24 hours", value: "__last_24_days__" },
            { displayName: "Last 3 days", value: "__last_3_days__" },
            { displayName: "Last 7 days", value: "__last_7_days__" },
            { displayName: "Last 14 days", value: "__last_14_days__" },
            { displayName: "Last 30 days", value: "__last_30_days__" },
        ]

        const matchedOption = options.find((option) => option.value === filter.value)

        return matchedOption ? matchedOption.displayName : filter.value
    }

    return filter.value
}

function removeGroup() {
    emit("removeGroup", props.groupIndex)
}

function addNewFilterToGroup() {
    emit("addNewFilterToGroup", props.groupIndex)
}

function removeFilter(filterIndex: number) {
    emit("removeFilter", props.groupIndex, filterIndex)
}

function editFilter(filter: Filter, filterIndex: number) {
    emit("editFilter", filter, props.groupIndex, filterIndex)
}

function setIconVisibility(filterIndex: number, isVisible: boolean) {
    iconVisibility.value[filterIndex] = isVisible
}

function checkIsArray(value?: string | string[] | Date) {
    return Array.isArray(value)
}
</script>

<style scoped>
.filter-value {
    @apply bg-primary-color-100 text-primary-color-500 px-1 py-0.5 rounded;
}
</style>
