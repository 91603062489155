<template>
    <div class="bg-gray-100 flex rounded-md text-sm cursor-pointer items-center w-full" @click="toggle">
        <div
            class="px-5 m-0.5 text-sm rounded-md py-2 flex-1 text-center"
            :class="{ 'bg-white shadow-sm border-gray-200 border': modelValue }"
        >
            {{ truthLabel }}
        </div>
        <div
            class="px-5 m-0.5 text-sm rounded-md py-2 flex-1 text-center"
            :class="{ 'bg-white shadow-sm border-gray-200 border': !modelValue }"
        >
            {{ falseLabel }}
        </div>
    </div>
</template>

<script setup lang="ts">
const props = defineProps({
    modelValue: {
        type: Boolean,
        required: true,
    },
    truthLabel: {
        type: String,
        default: "Yes",
    },
    falseLabel: {
        type: String,
        default: "No",
    },
})
const emit = defineEmits(["update:modelValue"])

function toggle() {
    emit("update:modelValue", !props.modelValue)
}
</script>
