<template>
    <GgmsInput v-model="collectionTableState.search" placeholder="Search values" :iconStart="SearchIcon" />

    <div class="px-4 py-4.5 border border-gray-200 shadow sm:rounded-lg h-52 overflow-auto">
        <GgmsLoading v-if="collectionTableState.isLoading" />
        <div v-else class="flex flex-col gap-y-5">
            <div v-for="(collection, i) in collections" :key="collection._id" class="flex items-center gap-x-2">
                <GgmsCheckBox v-model="localCollectionsSelectedState[i]" />
                <GgmsChip :isRemovable="false">{{ collection.displayName }}</GgmsChip>
            </div>

            <p v-if="!collections.length" class="text-gray-500 mb-4">
                {{ getNotFoundText("collections", collectionTableState.search) }}
            </p>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue"
import GgmsInput from "@/components/GgmsInput.vue"
import GgmsLoading from "@/components/GgmsLoading.vue"
import GgmsCheckBox from "@/components/GgmsCheckBox.vue"
import GgmsChip from "@/components/GgmsChip.vue"
import { SearchIcon } from "@heroicons/vue/outline"
import { useGridCollectionStore } from "@/stores/grid-collection"
import { debounce, getNotFoundText } from "@/shared/utils/helpers"

interface Props {
    collectionsSelectedState: boolean[]
    hasFilterCollections: boolean
    typeName: string
}

const props = defineProps<Props>()
const emit = defineEmits(["onCollectionSelect"])

const gridCollectionStore = useGridCollectionStore()
const collectionTableState = computed(() => gridCollectionStore.collectionTableState)

const localCollectionsSelectedState = ref<boolean[]>(props.collectionsSelectedState)

const collections = computed(() => collectionTableState.value.data)

function createFilterTag() {
    // get the selected collections ids which are selected in localCollectionsSelectedState
    const selectedCollectionsIds = [] as string[]
    localCollectionsSelectedState.value.forEach((selected, index) => {
        if (selected) {
            selectedCollectionsIds.push(collections.value[index]._id)
        }
    })

    emit("onCollectionSelect", selectedCollectionsIds)
}

watch(
    () => collectionTableState.value.search,
    debounce(() => {
        if (props.hasFilterCollections) {
            // this is to prevent the search from being triggered when the modal is closed
            return
        }
        collectionTableState.value.currentPage = 0
    }, 250)
)

watch(
    () => localCollectionsSelectedState.value,
    () => {
        createFilterTag()
    },
    { deep: true }
)

watch(
    () => props.collectionsSelectedState,
    () => {
        localCollectionsSelectedState.value = props.collectionsSelectedState
    },
    { deep: true }
)
</script>
