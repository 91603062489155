import { defineStore } from "pinia"
import { TableState } from "../shared/models/table"
import { ToastService } from "../shared/services/toast"
import { LandingPage } from "../shared/models/landing-page"
import { LandingPageService } from "../shared/services/landing-page"
import { useGridCollectionStore } from "./grid-collection"
import { useSocketStore } from "./socket"
import { GgmsError } from "../shared/services/client"

export const useLandingPageStore = defineStore("landing-pages", {
    state: () => ({
        tableState: {
            data: [],
            selectedData: [],
            currentPage: 0,
            lastPage: 0,
            pageLength: 50,
            numberOfPages: 0,
            total: 0,
            isLoading: false,
            isAllSelected: false,
            templateType: "",
            firstIds: [],
        } as TableState<LandingPage>,
        landingPage: {} as LandingPage,
        landingPageService: new LandingPageService(),
        toastService: new ToastService(),
        gridCollectionStore: useGridCollectionStore(),
        socketStore: useSocketStore(),
    }),
    actions: {
        async getLandingPages(isFromCollection = false) {
            try {
                this.tableState.isLoading = true

                let lastId = null
                let offset = null

                // If we're going to the next page, use keyset pagination.
                if (this.tableState.currentPage === (this.tableState?.lastPage ?? 0) + 1) {
                    lastId =
                        this.tableState.data.length > 0
                            ? this.tableState.data[this.tableState.data?.length - 1]._id
                            : null
                }
                // If we're jumping to a specific page, use offset-based pagination.
                else if (
                    this.tableState.currentPage !== (this.tableState?.lastPage ?? 0) + 1 &&
                    this.tableState.currentPage > 1
                ) {
                    offset = (this.tableState.currentPage - 1) * this.tableState.pageLength
                }

                const filtersGroups = this.gridCollectionStore?.grid?.filtersGroups?.length
                    ? encodeURIComponent(JSON.stringify(this.gridCollectionStore.grid.filtersGroups))
                    : undefined

                if (!this.gridCollectionStore?.grid?.filtersGroups?.length && isFromCollection) {
                    this.tableState.data = []
                    this.tableState.total = 0
                    this.tableState.selectedData = []
                    this.tableState.numberOfPages = Math.ceil(1)
                    this.tableState.lastPage = this.tableState.currentPage
                    return
                }

                const response = await this.landingPageService.getAll({
                    length: this.tableState.pageLength,
                    column: this.tableState.column,
                    order: this.tableState.order,
                    search: this.tableState.search,
                    filtersGroups,
                    lastId,
                    offset,
                })

                if (!response) {
                    return
                }

                // Store the first ID of the new page.
                if (this.tableState.firstIds && this.tableState.currentPage) {
                    this.tableState.firstIds[this.tableState.currentPage - 1] = response.data[0]?._id
                }

                this.tableState.selectedData = []
                this.tableState.data = response.data
                this.tableState.total = response.total
                this.tableState.numberOfPages = Math.ceil(this.tableState.total / this.tableState.pageLength)
                this.tableState.lastPage = this.tableState.currentPage
            } finally {
                this.tableState.isLoading = false
            }
        },

        async getLandingPagesGrid() {
            try {
                this.tableState.isLoading = true
                const response = await this.gridCollectionStore.getGrid("landing-pages")
                await this.getLandingPages()

                return response
            } finally {
                this.tableState.isLoading = false
            }
        },

        async updateLandingPagesGrid() {
            try {
                this.tableState.isLoading = true
                if (!this.gridCollectionStore.grid) return
                this.gridCollectionStore.grid.filtersGroups = this.gridCollectionStore.filtersGroups || []

                this.gridCollectionStore.gridResponse = await this.gridCollectionStore.updateGrid(
                    "landing-pages",
                    this.gridCollectionStore.grid
                )
                this.gridCollectionStore.grid = this.gridCollectionStore.gridResponse.grid

                await this.getLandingPages()
            } finally {
                this.tableState.isLoading = false
            }
        },

        async deleteLandingPage(id: string) {
            const response = await this.landingPageService.deleteOne(id)
            if (!response) {
                return
            }
            this.toastService.addToast({
                message: "Landing Page deleted successfully",
                type: "success",
            })
            this.tableState.data = this.tableState.data.filter((landingPage) => landingPage._id !== id)
        },

        async bulkDelete(ids: string[], all: boolean) {
            const { job } = await this.landingPageService.bulkDelete(ids)
            this.socketStore.addJob(job)
            this.tableState.data = this.tableState.data.filter((landingPage) => !ids.includes(landingPage._id))
            this.toastService.addToast({
                type: "success",
                message: `Successfully deleted ${all ? "all" : ids.length} landing pages.`,
            })
        },

        async checkLandingPageList(typename: string, contactIds: string[]) {
            try {
                return this.landingPageService.checkLandingPageList(typename, contactIds)
            } catch (error) {
                const err = error as GgmsError
                if (err.code === "ValidationError") {
                    this.toastService.addToast({
                        type: "error",
                        message: err.message,
                    })
                }
            }
        },
    },
})
