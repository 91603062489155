import { Client } from "./client"
import Crud from "./crud"
import { LandingPage } from "../models/landing-page"

const client = new Client()

export class LandingPageService extends Crud<LandingPage> {
    constructor() {
        super("crm/landing-pages")
    }

    getForms(): Promise<LandingPage[]> {
        return client.get(this.endpoint)
    }

    bulkDelete(ids: string[]) {
        return client.post(`${this.endpoint}/bulk/delete`, { ids })
    }

    checkLandingPageList(typeName: string, entityIds: string[]) {
        return client.get(`${this.endpoint}/listCheck`, { params: { typeName, entityIds } })
    }
}
