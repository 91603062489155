<template>
    <div class="flex flex-col gap-y-3">
        <p class="text-gray-900 text-lg leading-6 font-medium">Add tags</p>
        <div class="flex flex-wrap gap-2">
            <!-- TAGS ADDED HERE: -->
            <GgmsChip
                v-for="tag in toAddTags?.filter((tag, i) => workflowStore.toAdd[i])"
                class="h-6 mx-1"
                :key="tag._id"
                :bgColor="tag.backgroundColor"
                :textColor="tag.foregroundColor"
                @onRemove="removeTagFromList(tag, 'added')"
                >{{ tag.displayName }}</GgmsChip
            >
        </div>
        <GgmsInput v-model="toAddSearch" placeholder="Search values" :iconStart="SearchIcon"></GgmsInput>
        <div class="p-4 border border-gray-200 shadow sm:rounded-lg h-52 overflow-auto relative">
            <GgmsLoading v-if="tagStore.tableState.isLoading"></GgmsLoading>
            <template v-else>
                <div v-for="(tag, i) in toAddTags" :key="tag._id" class="flex items-center" :class="getTagClass(tag)">
                    <template v-if="!tag.isHidden">
                        <GgmsCheckBox v-model="workflowStore.toAdd[i]" :classes="['mr-3']">
                            <GgmsChip
                                :bgColor="tag.backgroundColor"
                                :textColor="tag.foregroundColor"
                                :isRemovable="false"
                                >{{ tag.displayName }}</GgmsChip
                            >
                        </GgmsCheckBox>
                    </template>
                </div>
                <div v-if="!toAddTags?.length">
                    <p class="text-gray-500 mb-4">{{ getNotFoundText("tags", tagStore.tableState.search) }}</p>
                </div>
            </template>
        </div>
    </div>
    <hr class="bg-gray-200 w-[445px] -mx-6 mt-5 mb-4" />
    <div class="flex flex-col gap-y-3">
        <p class="text-gray-900 text-lg leading-6 font-medium">Remove tags</p>
        <div class="flex flex-wrap gap-2">
            <!-- TAGS ADDED HERE: -->
            <GgmsChip
                v-for="tag in toRemoveTags?.filter((tag, i) => workflowStore.toRemove[i])"
                class="h-6 mx-1"
                :key="tag._id"
                :bgColor="tag.backgroundColor"
                :textColor="tag.foregroundColor"
                @onRemove="removeTagFromList(tag, 'removed')"
                >{{ tag.displayName }}</GgmsChip
            >
        </div>
        <GgmsInput v-model="toRemoveSearch" placeholder="Search values" :iconStart="SearchIcon"></GgmsInput>
        <div class="p-4 border border-gray-200 shadow sm:rounded-lg h-52 overflow-auto relative">
            <GgmsLoading v-if="tagStore.tableState.isLoading"></GgmsLoading>
            <template v-else>
                <div
                    v-for="(tag, i) in toRemoveTags"
                    :key="tag._id"
                    class="flex items-center"
                    :class="getTagClass(tag)"
                >
                    <template v-if="!tag.isHidden">
                        <GgmsCheckBox v-model="workflowStore.toRemove[i]" :classes="['mr-3']">
                            <GgmsChip
                                :bgColor="tag.backgroundColor"
                                :textColor="tag.foregroundColor"
                                :isRemovable="false"
                                >{{ tag.displayName }}</GgmsChip
                            >
                        </GgmsCheckBox>
                    </template>
                </div>
                <div v-if="!toRemoveTags?.length">
                    <p class="text-gray-500 mb-4">{{ getNotFoundText("tags", tagStore.tableState.search) }}</p>
                </div>
            </template>
        </div>
    </div>
</template>
<script setup lang="ts">
import { onMounted, ref, watch } from "vue"
import { SearchIcon } from "@heroicons/vue/outline"
import GgmsLoading from "@/components/GgmsLoading.vue"
import GgmsCheckBox from "@/components/GgmsCheckBox.vue"
import GgmsChip from "@/components/GgmsChip.vue"
import GgmsInput from "@/components/GgmsInput.vue"
import { debounce, getNotFoundText } from "@/shared/utils/helpers"
import { useWorkflowStore } from "@/stores/workflow"
import { useTagStore } from "@/stores/tag"
import { Tag } from "@/shared/models/tag"

const tagStore = useTagStore()
const workflowStore = useWorkflowStore()

const toAddSearch = ref("")
const toRemoveSearch = ref("")

const toAddTags = ref<Tag[] | undefined>([])
const toRemoveTags = ref<Tag[] | undefined>([])

function getTagClass(tag: Tag) {
    return !tag.isHidden ? "mb-4 last:mb-0" : ""
}

function removeTagFromList(tag: Tag, collection: "added" | "removed") {
    if (collection === "added") {
        const index = toAddTags.value?.findIndex((t) => t._id === tag._id)
        if (index !== undefined && index !== -1) {
            workflowStore.toAdd[index] = false
        }
    } else {
        const index = toRemoveTags.value?.findIndex((t) => t._id === tag._id)
        if (index !== undefined && index !== -1) {
            workflowStore.toRemove[index] = false
        }
    }
}

onMounted(async () => {
    await tagStore.getTags()
    toAddTags.value = await tagStore.getAllTagsBySearch(toAddSearch.value)
    toRemoveTags.value = await tagStore.getAllTagsBySearch(toRemoveSearch.value)
})

watch(
    () => [workflowStore.toAdd, workflowStore.toRemove],
    () => {
        workflowStore.areButtonDisabled =
            !workflowStore.toAdd.filter((data) => data).length && !workflowStore.toRemove.filter((data) => data).length
    },
    { deep: true }
)

watch(
    () => toAddSearch.value,
    debounce(async () => {
        toAddTags.value = await tagStore.getAllTagsBySearch(toAddSearch.value)
    }, 250)
)

watch(
    () => toRemoveSearch.value,
    debounce(async () => {
        toRemoveTags.value = await tagStore.getAllTagsBySearch(toRemoveSearch.value)
    }, 250)
)
</script>
